import React, { useEffect, useState, useRef } from "react";
import "../scss/SoundList.scss";

function SoundList() {
  const [sounds, setSounds] = useState([]);
  const [soundData, setSoundData] = useState([]);
  const [showingSequence, setShowingSequence] = useState(false);
  const [sequencePlaying, setSequencePlaying] = useState(false);
  const [sequenceSounds, setSequenceSounds] = useState([]);
  const sequencePlayingRef = useRef(sequencePlaying); //Needed for use inside the async function https://stackoverflow.com/a/62771076/958827

  useEffect(() => {
    async function getSounds() {
      try {
        let response = await fetch("https://soundboard.nathan-hunt.co.uk/api/");
        return await response.json();
      } catch (error) {
        console.error(error);
      }
    }

    getSounds().then((result) => {
      setSounds(result);
      setSoundData(result);
    });
  }, []);

  const filterSounds = (event) => {
    const text = event.target.value.toLowerCase();
    const searchWords = text.split(" ");

    setSoundData(
      sounds.reduce((result, sectionData) => {
        const { title, data } = sectionData;
        const filteredData = data.filter((element) =>
          searchWords.every((v) => element.description.includes(v))
        );

        if (filteredData.length !== 0) {
          result.push({
            title,
            data: filteredData,
          });
        }

        return result;
      }, [])
    );
  };

  const soundbiteClicked = (sound) => {
    showingSequence ? addSequenceSound(sound) : playSound(sound.fileName);
  };

  const playSound = async (fileName) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(fileName);
      audio.play();

      audio.onended = function () {
        resolve();
      };
    });
  };

  const addSequenceSound = (sound) => {
    const sounds = sequenceSounds;
    sounds.push(sound);
    setSequenceSounds([...sounds]);
  };

  const removeSequenceSound = (index) => {
    const sounds = sequenceSounds;
    sounds.splice(index, 1);
    setSequenceSounds([...sounds]);
  };

  const playSequence = async () => {
    setSequencePlaying(true);
    sequencePlayingRef.current = true;
    for (const sound of sequenceSounds) {
      if (sequencePlayingRef.current === false) {
        break;
      }
      await playSound(sound.fileName);
    }
    stopSequence();
  };

  const stopSequence = () => {
    setSequencePlaying(false);
    sequencePlayingRef.current = false;
  };

  const clearSequence = () => {
    stopSequence();
    setSequenceSounds([]);
  };

  function Section(props) {
    return (
      <div className="section">
        <div>
          <h2>{props.title}</h2>
          <div className="row">
            {props.sounds.map((sound, index) => (
              <div key={index} className="mx-1">
                <div
                  className={`btn mb-2 ${
                    showingSequence ? "btn-warning" : "btn-primary"
                  }`}
                  onClick={() => soundbiteClicked(sound)}
                >
                  {sound.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  function Sequence() {
    return (
      <div className="row">
        <div className="col-12">
          <h2 className="float-left">Sequence</h2>
          {sequencePlaying ? (
            <div
              className="float-left ml-2 btn mb-2 btn-danger"
              onClick={() => stopSequence()}
            >
              Stop
            </div>
          ) : (
            <div
              className={`float-left ml-2 btn mb-2 ${
                sequenceSounds.length > 0 ? "btn-success" : "btn-dark disabled"
              }`}
              onClick={() => playSequence()}
            >
              Play
            </div>
          )}
          <div
            className={`float-left ml-2 btn mb-2 ${
              sequenceSounds.length > 0
                ? "btn-outline-dark"
                : "btn-dark disabled"
            }`}
            onClick={() => clearSequence()}
          >
            Clear
          </div>

          <div className="clearfix"></div>
          <div className="border border-primary p-4">
            <div className="row">
              {sequenceSounds.map((sound, index) => (
                <div key={index} className="mx-1">
                  <div
                    className="btn mb-2 btn-primary"
                    onClick={() => removeSequenceSound(index)}
                  >
                    {sound.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="py-3 sticky-top bg-white">
        <div className="container">
          <div className="row">
            <div className="col-7 col-md-6">
              <input
                className="form-control"
                type="text"
                placeholder="Search..."
                onChange={filterSounds}
              />
            </div>
            <div className="col-5 col-md-6 text-right">
              <div
                className="btn btn-warning mb-2"
                onClick={() => setShowingSequence(!showingSequence)}
              >
                Toggle Sequencing
              </div>
            </div>
          </div>
          {showingSequence && <Sequence />}
        </div>
      </div>
      <div className="container">
        <div className="sections">
          {soundData
            ? soundData.map((section, index) => (
                <Section
                  key={index}
                  title={section.title}
                  sounds={section.data}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
}

export default SoundList;
